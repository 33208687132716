import mixpanel from "mixpanel-browser";
import { ehProducao } from "./api";

mixpanel.init("4ba498d406a7f98d9cffc4a767872d87", {
  debug: true,
});

let actions = {
  identify: (id) => {
    if (ehProducao()) mixpanel.identify(id);
  },
  track: (name) => {
    if (ehProducao()) mixpanel.track(name);
  },
  people: {
    set: (props) => {
      if (ehProducao()) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
