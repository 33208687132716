import { lazy } from "react";

import InfoMoviPay from "../pages/transacoes-movipay/infoMoviPay";
import Loadable from "@/components/Loadable";
import MainLayout from "@/layout/MainLayout";
const DashboardOperacoes = Loadable(
  lazy(() => import("@/pages/dashboard-operacoes"))
);
const Dashboard = Loadable(lazy(() => import("@/pages/dashboard")));
const Usuarios = Loadable(lazy(() => import("@/pages/usuarios")));
const IndicacoesClientes = Loadable(
  lazy(() => import("@/pages/usuarios/indicacoes-clientes"))
);
const Pedido = Loadable(
  lazy(() => import("@/pages/dashboard-operacoes/pedido"))
);

const Estatisticas = Loadable(
  lazy(() => import("@/pages/estatisticas/estatisticas-gerais"))
);
const DadosGerais = Loadable(
  lazy(() => import("@/pages/estatisticas/dados-gerais"))
);
const Estorno = Loadable(
  lazy(() => import("@/pages/dashboard-operacoes/pedido/estorno"))
);
const Usuario = Loadable(lazy(() => import("@/pages/usuarios/usuario")));
const AlteracaoUsuario = Loadable(
  lazy(() => import("@/pages/usuarios/alteracao-usuario"))
);
const Configuracoes = Loadable(lazy(() => import("@/pages/configuracao")));
const Cupons = Loadable(lazy(() => import("@/pages/configuracao/cupons")));
const CadastroCupons = Loadable(
  lazy(() => import("@/pages/configuracao/cupons/cadastro-cupons"))
);
const EditarCupom = Loadable(
  lazy(() => import("@/pages/configuracao/cupons/editar-cupom"))
);

const Avisos = Loadable(lazy(() => import("@/pages/configuracao/avisos")));
const CadastroAviso = Loadable(
  lazy(() => import("@/pages/configuracao/avisos/cadastro-aviso"))
);
const DetalhesAviso = Loadable(
  lazy(() => import("@/pages/configuracao/avisos/detalhes-aviso"))
);

const UserWarnings = Loadable(
  lazy(() => import("@/pages/dashboard/user-warnings/ListWarnings"))
);
const DetailsUserWarning = Loadable(
  lazy(() => import("@/pages/dashboard/user-warnings/details-warning"))
);
const ConfigUserNotifications = Loadable(
  lazy(() => import("@/pages/dashboard/user-warnings/configs-notifications"))
);

const ActiveUsers = Loadable(
  lazy(() => import("@/pages/configuracao/active-users"))
);

const Parceiros = Loadable(lazy(() => import("@/pages/parceiros")));
const ParceirosMoviPay = Loadable(
  lazy(() => import("@/pages/parceiros-movipay"))
);

const EdicaoParceiroMoviPay = Loadable(
  lazy(() => import("@/pages/parceiros-movipay/edicao-parceiro"))
);

const EdicaoWhiteLabelParceiroMoviPay = Loadable(
  lazy(() => import("@/pages/parceiros-movipay/white-label"))
);

const AlterarSenha = Loadable(lazy(() => import("@/pages/alterar-senha")));
const TaxasParceiroMoviPay = Loadable(
  lazy(() => import("@/pages/parceiros-movipay/taxas"))
);

const PagamentosParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/pagamentos"))
);

const UsuariosParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/usuarios-parceiro"))
);

const UsuariosParceiroMoviPay = Loadable(
  lazy(() => import("@/pages/parceiros-movipay/usuarios-parceiro"))
);

const UsuarioMoviPay = Loadable(
  lazy(() => import("@/pages/parceiros-movipay/usuarios-parceiro/usuario"))
);

const EdicaoParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/edicao-parceiro"))
);

const EdicaoWhiteLabelParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/white-label"))
);

const Comissoes = Loadable(lazy(() => import("@/pages/comissoes")));
const ControleAcesso = Loadable(lazy(() => import("@/pages/controle-acesso")));
const PerfilAcesso = Loadable(
  lazy(() => import("@/pages/controle-acesso/perfilAcesso"))
);
const CadastroPerfilAcesso = Loadable(
  lazy(() => import("@/pages/controle-acesso/perfilAcesso/cadastro"))
);
const Transferencias = Loadable(lazy(() => import("@/pages/transferencias")));
const TransacoesMoviPay = Loadable(
  lazy(() => import("@/pages/transacoes-movipay"))
);
const ChargebackMoviPay = Loadable(
  lazy(() => import("@/pages/chargeback-movipay"))
);
const DetalheChargeback = Loadable(
  lazy(() => import("@/pages/chargeback-movipay/DetalheChargeback"))
);
const AntecipacoesMoviPay = Loadable(
  lazy(() => import("@/pages/antecipacao-movipay"))
);
const TransferenciasMoviPay = Loadable(
  lazy(() => import("@/pages/transferencias-movipay"))
);
const ConfiguracoesMoviPay = Loadable(
  lazy(() => import("@/pages/configuracao-movipay"))
);
const SaldosMoviPay = Loadable(lazy(() => import("@/pages/saldos-movipay")));
const AntecipacaoMoviPay = Loadable(
  lazy(() => import("@/pages/antecipacao-movipay/edicao-antecipacao"))
);
const TransferenciaMoviPay = Loadable(
  lazy(() => import("@/pages/transferencias-movipay/edicao"))
);
const Taxas = Loadable(lazy(() => import("@/pages/configuracao/taxas")));
const Gateways = Loadable(lazy(() => import("@/pages/configuracao/gateways")));
const Comprovante = Loadable(
  lazy(() =>
    import(
      "@/pages/dashboard-operacoes/pedido/pagamentos-solicitados/comprovante-pagamento"
    )
  )
);
const ComprovanteEstorno = Loadable(
  lazy(() =>
    import(
      "@/pages/dashboard-operacoes/pedido/pagamentos-solicitados/comprovante-estorno"
    )
  )
);

const ComprovanteTransferencia = Loadable(
  lazy(() => import("@/pages/transferencias/comprovante"))
);

const ExtratoOperacoesLiquidadas = Loadable(
  lazy(() => import("@/pages/dashboard/extrato-operacoes-liquidadas"))
);
const EdicaoTCParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/editar-tc"))
);
const EnvioEmails = Loadable(
  lazy(() => import("@/pages/estatisticas/dados-gerais/envios-emails"))
);
const EnvioSms = Loadable(
  lazy(() => import("@/pages/estatisticas/dados-gerais/envios-sms"))
);

const MetadataMarketing = Loadable(lazy(() => import("@/pages/marketing")));
const Auditoria = Loadable(
  lazy(() => import("@/pages/configuracao/auditoria"))
);
const EdicaoFaixaComissao = Loadable(
  lazy(() => import("@/pages/parceiros/editar-faixa-comissao"))
);

const EditarTaxasParcelas = Loadable(
  lazy(() => import("@/pages/parceiros/editar-taxas-por-parcela"))
);

const Metas = Loadable(lazy(() => import("@/pages/configuracao/metas")));
const RegistrarMetas = Loadable(
  lazy(() => import("@/pages/configuracao/metas/registrar-metas"))
);
const EditarMeta = Loadable(
  lazy(() => import("@/pages/configuracao/metas/editar-meta"))
);

const MainRoutes = {
  path: "area-admin",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <DashboardOperacoes />,
    },
    {
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      path: "transferencias",
      element: <Transferencias />,
    },
    {
      path: "transacoes-movipay",
      element: <TransacoesMoviPay />,
    },
    {
      path: "transacoes-movipay/:id",
      element: <InfoMoviPay />,
    },
    {
      path: "antecipacoes-movipay",
      element: <AntecipacoesMoviPay />,
    },
    {
      path: "antecipacao-movipay/:idAntecipacao",
      element: <AntecipacaoMoviPay />,
    },
    {
      path: "transferencias-movipay",
      element: <TransferenciasMoviPay />,
    },
    {
      path: "transferencia-movipay/:idTransfer",
      element: <TransferenciaMoviPay />,
    },
    {
      path: "configuracoes-movipay",
      element: <ConfiguracoesMoviPay />,
    },
    {
      path: "saldos-movipay",
      element: <SaldosMoviPay />,
    },
    {
      path: "chargeback-movipay",
      element: <ChargebackMoviPay />,
    },
    {
      path: "chargeback-movipay/:id",
      element: <DetalheChargeback />,
    },
    {
      path: "pedido/:idRequest",
      element: <Pedido />,
    },

    {
      path: "pedido/:idRequest/estorno",
      element: <Estorno />,
    },
    {
      path: "clientes",
      element: <Usuarios />,
    },
    {
      path: "indicacoes-clientes",
      element: <IndicacoesClientes />,
    },
    {
      path: "usuario",
      element: <Usuario />,
    },

    {
      path: "usuario/:idUser",
      element: <AlteracaoUsuario />,
    },
    {
      path: "usuario/company/:idCompany",
      element: <Usuario />,
    },
    {
      path: "usuario/company/:idCompany/:idUser",
      element: <Usuario />,
    },
    {
      path: "usuario/admin/:idUser",
      element: <Usuario />,
    },

    {
      path: "parceiros-movipay",
      element: <ParceirosMoviPay />,
    },
    {
      path: "parceiros",
      element: <Parceiros />,
    },
    {
      path: "alterar-senha",
      element: <AlterarSenha />,
    },
    {
      path: "taxas-parceiro-movipay/:idCompany",
      element: <TaxasParceiroMoviPay />,
    },

    {
      path: "pagamentos-parceiro/:idCompany",
      element: <PagamentosParceiro />,
    },

    {
      path: "comissoes",
      element: <Comissoes />,
    },
    {
      path: "usuarios-parceiro/:idCompany",
      element: <UsuariosParceiro />,
    },
    {
      path: "usuarios-parceiro-movipay/:idCompany",
      element: <UsuariosParceiroMoviPay />,
    },
    {
      path: "parceiro/:idCompany",
      element: <EdicaoParceiro />,
    },

    {
      path: "white-label/:idCompany",
      element: <EdicaoWhiteLabelParceiro />,
    },

    {
      path: "parceiro-movipay/:idCompany",
      element: <EdicaoParceiroMoviPay />,
    },

    {
      path: "parceiro-movipay/white-label/:idCompany",
      element: <EdicaoWhiteLabelParceiroMoviPay />,
    },

    {
      path: "usuario-movipay/:idUser/:idCompany",
      element: <UsuarioMoviPay />,
    },

    {
      path: "perfis-acesso",
      element: <PerfilAcesso />,
    },

    {
      path: "controle-acesso",
      element: <ControleAcesso />,
    },
    {
      path: "perfil-acesso",
      element: <CadastroPerfilAcesso />,
    },
    {
      path: "perfil-acesso/:idAccessProfile",
      element: <CadastroPerfilAcesso />,
    },
    {
      path: "configuracoes",
      element: <Configuracoes />,
    },
    {
      path: "cupons",
      element: <Cupons />,
    },
    {
      path: "cadastro-cupons",
      element: <CadastroCupons />,
    },
    {
      path: "cupons/:idCupom",
      element: <EditarCupom />,
    },
    {
      path: "avisos",
      element: <Avisos />,
    },
    {
      path: "cadastro-aviso",
      element: <CadastroAviso />,
    },
    {
      path: "detalhes-aviso/:id",
      element: <DetalhesAviso />,
    },
    {
      path: "avisos-usuario",
      element: <UserWarnings />,
    },
    {
      path: "avisos-usuario/detalhes/:id",
      element: <DetailsUserWarning />,
    },
    {
      path: "avisos-usuario/configuracoes",
      element: <ConfigUserNotifications />,
    },
    {
      path: "usuarios-ativos",
      element: <ActiveUsers />,
    },
    {
      path: "relatorios",
      element: <DadosGerais />,
    },
    {
      path: "estatisticas",
      element: <Estatisticas />,
    },
    {
      path: "taxas",
      element: <Taxas />,
    },
    {
      path: "gateways",
      element: <Gateways />,
    },

    {
      path: "recibo-operacao/:idRequest/:token/:idRequestBill",
      element: <Comprovante />,
    },
    {
      path: "comprovante-estorno/:idRequest/:idRequestBill",
      element: <ComprovanteEstorno />,
    },
    {
      path: "comprovante-transferencia/:idTransfer",
      element: <ComprovanteTransferencia />,
    },
    {
      path: "operacoes-liquidadas",
      element: <ExtratoOperacoesLiquidadas />,
    },
    {
      path: "tc-parceiro/:idCompany",
      element: <EdicaoTCParceiro />,
    },
    {
      path: "faixa-comissao/:idCompany",
      element: <EdicaoFaixaComissao />,
    },
    {
      path: "taxas-parcelas/:idCompany",
      element: <EditarTaxasParcelas />,
    },
    {
      path: "envios-emails",
      element: <EnvioEmails />,
    },
    {
      path: "envios-sms",
      element: <EnvioSms />,
    },
    {
      path: "metadata-marketing",
      element: <MetadataMarketing />,
    },
    {
      path: "auditoria",
      element: <Auditoria />,
    },
    {
      path: "metas",
      element: <Metas />,
    },
    {
      path: "registrar-metas-do-mes",
      element: <RegistrarMetas />,
    },
    {
      path: "editar-meta",
      element: <EditarMeta />,
    },
    {
      path: "*",
      element: <DashboardOperacoes />,
    },
  ],
};

export default MainRoutes;
