import { useEffect, useState } from "react";
import { formatMonetario } from "@/services/utils";
import { get } from "@/services/apiMoviPay";
import { useDispatch } from "react-redux";
import { activeSpinner, cancelSpinner } from "@/store/reducers/geral";
import { useParams } from "react-router";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@/components/Accordion";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import BotaoSecundario from "@/components/BotaoSecundario";

export default function InfoMoviPay() {
  const [history, setHistory] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const gateway = data.id_gateway === 406 ? "Safe2Pay" : "PagSeguro";

  useEffect(() => {
    const getData = async () => {
      dispatch(activeSpinner());
      const transaction = await get(`secure/transaction/${params.id}`);
      const logs = await get(
        `secure/logs-transaction/${transaction.id_transaction}`
      );
      setData(transaction);
      setHistory(logs);
      dispatch(cancelSpinner());
    };
    getData();
  }, [dispatch, params.id]);

  const retornaFormaPagamento = (payment_method) => {
    if (payment_method === "credit_card") {
      return (payment_method = "Cartão de Crédito");
    }

    return payment_method;
  };

  return (
    <Container>
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}>
        <Typography>{`ID Cobrança: ${data.id_transaction} `}</Typography>
        <Typography style={{ marginBottom: "0.5rem" }}>{data.title}</Typography>

        <Accordion>
          <AccordionSummary aria-controls="panel2d-content">
            <Typography>Dados pessoais</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Nome"
                  value={data.client_name || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                {data.document && (
                  <TextField
                    label="CPF/CNPJ"
                    value={data.document || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="E-mail"
                  value={data.client_email || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", alignItems: "flex-end" }}
                md={3}
                onClick={() => {
                  window.open(
                    `https://api.whatsapp.com/send?phone=55${data.whatsapp}`,
                    "_blank"
                  );
                }}>
                <TextField
                  label="Telefone"
                  value={data.client_phone || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="CEP"
                  value={data.zip_code || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Rua"
                  value={data.street || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>{" "}
              <Grid item xs={12} md={3}>
                <TextField
                  label="Número"
                  value={data.number || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Complemento"
                  value={data.complement || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Bairro"
                  value={data.neighborhood || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Cidade"
                  value={data.city || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Estado"
                  value={data.province || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography>Histórico da operação</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Mensagem</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!history &&
                  history.map((historyLog) => (
                    <TableRow key={historyLog.id_transaction_log}>
                      <TableCell>
                        {new Date(historyLog.insert_datetime).toLocaleString(
                          "pt-br"
                        )}
                      </TableCell>
                      <TableCell>{historyLog.message}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={true}>
          <AccordionSummary>
            <Typography>Dados da transação</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Forma Pagamento"
                  value={retornaFormaPagamento(data.payment_method) || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Valor"
                  value={formatMonetario(data.total) || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  label="Descrição"
                  value={data.title || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Gateway"
                  value={gateway || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              {!!data.payment_datetime && (
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Data de pagamento"
                    value={
                      `${new Date(data.payment_datetime).toLocaleString(
                        "pt-br"
                      )}` || ""
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              )}
            </Grid>
            {!data.payment_datetime && (
              <BotaoSecundario
                onClick={() => {
                  window.open(data.payment_link, "_blank");
                }}
                style={{ padding: "8px 32.5px", marginTop: "1em" }}>
                Link de pagamento
              </BotaoSecundario>
            )}
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Container>
  );
}
