import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import BusinessIcon from "@mui/icons-material/Business";
import BarChartIcon from "@mui/icons-material/BarChart";
import GppGoodIcon from "@mui/icons-material/GppGood";
import SettingsIcon from "@mui/icons-material/Settings";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import MoneyIcon from "@mui/icons-material/Money";

const dashboard = {
  id: "group-dashboard-QuitaBoletos",
  title: "QuitaBoletos",
  url: "/area-admin",
  type: "group",
  children: [
    {
      id: "dashboard-operacoes",
      title: "OPERAÇÕES",
      type: "item",
      url: "",
      icon: DashboardIcon,
      breadcrumbs: false,
      permissao: "visualizar-pedidos",
    },
    {
      id: "dashboard",
      title: "DASHBOARD",
      type: "item",
      url: "dashboard",
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "clientes",
      title: "CLIENTES",
      type: "item",
      url: "clientes",
      icon: GroupIcon,
      breadcrumbs: false,
      permissao: "visualizar-clientes",
    },
    {
      id: "parceiros",
      title: "PARCEIROS",
      type: "item",
      url: "parceiros",
      icon: BusinessIcon,
      breadcrumbs: false,
      permissao: "visualizar-parceiros",
    },

    {
      id: "transferencias",
      title: "SALDOS/TRANSFERÊNCIAS",
      type: "item",
      url: "transferencias",
      icon: MoneyIcon,
      breadcrumbs: false,
      permissao: "visualizar-saldos",
      badge: true,
    },
    {
      id: "comissoes",
      title: "COMISSÕES",
      type: "item",
      url: "comissoes",
      icon: CurrencyExchangeIcon,
      breadcrumbs: false,
      permissao: "visualizar-comissoes",
    },
    {
      id: "estatisticas",
      title: "ESTATÍSTICAS",
      type: "item",
      url: "estatisticas",
      icon: BarChartIcon,
      breadcrumbs: false,
    },
    {
      id: "relatorios",
      title: "RELATÓRIOS",
      type: "item",
      url: "relatorios",
      icon: BarChartIcon,
      breadcrumbs: false,
    },
    {
      id: "controle-acesso",
      title: "CONTROLE DE ACESSO",
      type: "item",
      url: "controle-acesso",
      icon: GppGoodIcon,
      breadcrumbs: false,
      permissao: "controle-acesso",
    },

    {
      id: "marketing",
      title: "MARKETING",
      type: "item",
      url: "metadata-marketing",
      icon: BarChartIcon,
      breadcrumbs: false,
      permissao: "visualizar-dados-marketing",
    },
    {
      id: "configuracoes-gerais",
      title: "CONFIGURAÇÕES",
      type: "item",
      url: "configuracoes",
      icon: SettingsIcon,
      breadcrumbs: false,
      permissao: "configuracoes-gerais",
    },
  ],
};

export default dashboard;
